import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ResourcePage from '../../components/industry-resources/ResourcePage'
import SEO from '../../components/SEO'
import ExpandableImage from '../../components/ExpandableImage'
import ResourceLink from '../../components/industry-resources/ResourceLink'

const AllergyIconsPage = () => {
  const { markdownRemark, printResource } = useStaticQuery(graphql`
    query AllergyIconsPageQuery {
      markdownRemark(
        frontmatter: { path: { eq: "/industry-resources/allergy-icons" } }
      ) {
        frontmatter {
          title
          path
          seoImage {
            ...SeoImageSharpFragment
          }
        }
      }
      printResource: allDatoCmsPrintResourceAllergyIcon(
        sort: { fields: position }
      ) {
        edges {
          node {
            title
            description
            descriptionNode {
              childMarkdownRemark {
                html
              }
            }
            isDownload
            downloadUrl
            fileType
            fileSize
            images {
              url
              alt
              title
              customData
              ...DatoImageModalFragment
            }
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const allResources = printResource.edges

  return (
    <ResourcePage uri={pageData.path}>
      <SEO
        title={pageData.title}
        image={pageData.seoImage.childImageSharp.fixed.src}
      />

      <section className="c-section c-section--resourcesList">
        <div className="container">
          <div className="c-resourcesList c-resourcesList--print">
            {allResources.map(({ node }, i) => {
              if (i === 0) {
                return (
                  <div key="allery-icons-feature" className="c-resourcesItem">
                    <div className="row">
                      <div className="col-12 text-center col-lg-8">
                        <div className="row">
                          {node.images.map((image, i) => {
                            if (i === 0) {
                              return (
                                <div
                                  key={`allery-icons-feature-image-${i}`}
                                  className="col-12"
                                >
                                  <ExpandableImage imageData={image}>
                                    <img
                                      src={`${image.url}?fit=crop&w=750&h=405`}
                                      className="c-resourcesItem__thumbnail img-fluid"
                                    />
                                  </ExpandableImage>
                                </div>
                              )
                            }

                            return (
                              <div
                                key={`allery-icons-feature-image-${i}`}
                                className="d-none d-lg-block col-lg-3"
                              >
                                <ExpandableImage imageData={image}>
                                  <img
                                    src={`${image.url}?fit=crop&w=165&h=165`}
                                    className="c-resourcesItem__thumbnail img-fluid"
                                  />
                                </ExpandableImage>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4 px-xl-5">
                        <div className="row">
                          <div className="col-12">
                            <h2 className="c-resourcesItem__title">
                              {node.title}
                            </h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  node.descriptionNode.childMarkdownRemark.html,
                              }}
                            />
                          </div>
                          <div className="col-12 col-lg-4">
                            <div className="row">
                              <div className="col-6 col-lg-12">
                                {node.isDownload && (
                                  <>
                                    <h3 className="c-resourceItem__ext">
                                      {`${node.fileType} file`}
                                    </h3>
                                    <p>{node.fileSize}</p>
                                  </>
                                )}
                              </div>
                              <div className="col-6 text-right col-lg-12 text-lg-left">
                                <ResourceLink
                                  url={node.downloadUrl}
                                  isDownload={node.isDownload}
                                />
                              </div>
                              {/* <div className="col-12 col-lg-12">
                                <SocialLinks />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }

              return (
                <div key={`allery-icons-${i}`} className="c-resourcesItem">
                  <div className="row">
                    <div className="col-12 text-center col-md-4">
                      {node.images.map((image, i) => {
                        if (i === 0) {
                          return (
                            <ExpandableImage
                              key={`allery-icons-${i}`}
                              imageData={image}
                            >
                              <img
                                src={`${image.url}?fit=crop&w=360&h=360`}
                                className="c-resourcesItem__thumbnail img-fluid"
                              />
                            </ExpandableImage>
                          )
                        }
                      })}
                    </div>
                    <div className="col-12 col-md-8 col-lg-4 px-xl-5">
                      <h2 className="c-resourcesItem__title">{node.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.descriptionNode.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="row">
                        <div className="col-6 col-lg-12">
                          {node.isDownload && (
                            <>
                              <h3 className="c-resourceItem__ext">
                                {`${node.fileType} file`}
                              </h3>
                              <p>{node.fileSize}</p>
                            </>
                          )}
                        </div>
                        <div className="col-6 text-right col-lg-12 text-lg-left">
                          <ResourceLink
                            url={node.downloadUrl}
                            isDownload={node.isDownload}
                          />
                        </div>
                        {/* <div className="col-12 col-lg-12">
                          <SocialLinks />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </ResourcePage>
  )
}

const SocialLinks = () => {
  return (
    <ul className="c-socialLinks">
      <li className="c-socialLinks__item">
        <a
          className="c-socialLinks__link"
          href="https://www.facebook.com/ErudusUK/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="c-socialLinks__icon"
            srcSet="/images/icons/Social/Facebook.svg"
            src="/images/icons/Social/Facebook.png"
            alt="Facebook"
          />
        </a>
      </li>

      <li className="c-socialLinks__item">
        <a
          className="c-socialLinks__link"
          href="https://www.linkedin.com/company/erudus-limited/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="c-socialLinks__icon"
            srcSet="/images/icons/Social/LinkedIn.svg"
            src="/images/icons/Social/LinkedIn.png"
            alt="LinkedIn"
          />
        </a>
      </li>

      <li className="c-socialLinks__item">
        <a
          className="c-socialLinks__link"
          href="https://twitter.com/ErudusUK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="c-socialLinks__icon"
            srcSet="/images/icons/Social/Twitter.svg"
            src="/images/icons/Social/Twitter.png"
            alt="Twitter"
          />
        </a>
      </li>

      <li className="c-socialLinks__item">
        <a
          className="c-socialLinks__link"
          href="https://github.com/Erudus"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="c-socialLinks__icon"
            srcSet="/images/icons/Social/Github.svg"
            src="/images/icons/Social/Github.png"
            alt="GitHub"
          />
        </a>
      </li>
    </ul>
  )
}

export default AllergyIconsPage
