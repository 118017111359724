import React from 'react'
import { Link } from 'gatsby'

import Page from '../Page'
import Newsletter from '../../components/Newsletter'

import { industryResources } from '../../../config/navigation'

import HeaderSVG from '../../assets/svg/industry-resources/Resources-Icon.svg'

const ResourcePage = ({ children, uri }) => {
  return (
    <Page className="p-industryResources p-industryResources--icons has-fingerPrints">
      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner pb-0">
            <div className="c-pageHeader mb-3">
              <div className="row">
                <div className="col-12 col-sm-7 col-md-10">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <span className="c-pageHeader__title">
                        Industry Resources
                      </span>
                    </div>
                    <div className="col-12 col-lg-8">
                      <h1 className="c-pageHeader__intro">
                        <strong>Make the most of Erudus</strong> with our range
                        of free downloadable resources.
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-5 col-md-2 text-center">
                  <HeaderSVG
                    className="c-pageHeader__image img-fluid"
                    width="140"
                  />
                </div>
              </div>
            </div>
            <div className="c-resourceHeaderMenuWrapper">
              <div className="form-group pb-3 d-block d-lg-none">
                <select
                  value={uri}
                  onChange={(event) => (window.location = event.target.value)}
                  className="form-control"
                >
                  {industryResources.map((link) => (
                    <option key={`${link.name}_mobile`} value={link.to}>
                      {link.name}
                    </option>
                  ))}
                </select>
              </div>

              <ul className="c-resourceHeaderMenu d-none d-lg-flex">
                {industryResources.map((link) => (
                  <li
                    key={link.name}
                    className={uri == link.to ? `is-active` : ``}
                  >
                    <Link to={link.to} title={link.name}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {children}

      <Newsletter />
    </Page>
  )
}

export default ResourcePage
