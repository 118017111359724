import React from 'react'
import PropTypes from 'prop-types'

import DownloadSVG from '../../assets/svg/download.svg'
import ArrowRightSVG from '../../assets/svg/arrow-right.svg'

const ResourceLink = ({ url, isDownload }) => {
  if (!isDownload) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="c-button c-button--secondary mb-4"
      >
        <ArrowRightSVG className="mr-2" />
        View
      </a>
    )
  }

  return (
    <a
      download
      href={url}
      target="_blank"
      className="c-button c-button--secondary mb-4"
    >
      <DownloadSVG className="mr-2" />
      Download
    </a>
  )
}

ResourceLink.defaultProps = {
  isDownload: false,
}

ResourceLink.propTypes = {
  url: PropTypes.string,
  isDownload: PropTypes.bool,
}

export default ResourceLink
